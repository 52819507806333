$igi__menu-overlay-width-xs: 200px;
$igi__menu-overlay-width-mobile: 300px;
$igi__menu-overlay-width: 378px;
$igi__mobile-break-point: 768px;
$igi__xl-break-point: 1440px;
$igi__xs-break-point: 440px;
$igi__transition: 0.5s;
$igi__color-link: #80949B;
$igi__color-parent: #022A39;
$igi__font-family-paralucent: "Paralucent", sans-serif;
$igi__font-family-roboto: "Roboto", sans-serif;
$igi__border-color: #BFC9CD;
$igi__color-link: #022A39;
$igi__color-link-menu: #80949b;
$igi__color-link-menu-hover: #55a8f0;
$igi__color-blue: #253081;
$igi__color-icon-blue: #2C2C31;
$igi__color-icon-green: #3D67AF;

.ImacHeader.theme-class--igi {
    padding-bottom: 20px;
    border-radius: 0;
}

.theme-class--igi {
    .ImacMenuContainer {
        &__close {
            &::before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44'%3E%3Cg id='Group_7908' data-name='Group 7908' transform='translate(-287 -38)'%3E%3Crect id='Rectangle_86' data-name='Rectangle 86' width='42' height='42' rx='21' transform='translate(288 39)' fill='none' stroke='%232c2c31' stroke-width='2'/%3E%3Cg id='Icon_feather-plus' data-name='Icon feather-plus' transform='translate(309 39.494) rotate(45)'%3E%3Cpath id='Path_5280' data-name='Path 5280' d='M18,7.5v14' transform='translate(-3.5)' fill='none' stroke='%232c2c31' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='Path_5281' data-name='Path 5281' d='M7.5,18h14' transform='translate(0 -3.5)' fill='none' stroke='%232c2c31' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }
    .ImacIcon {
        width: 42px;
        height: 42px;
        background-color: red;
        border-radius: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &.ImacIcon--bg-blue {
            background-color: $igi__color-icon-blue;
        }

        &.ImacIcon--bg-white {
            background-color: white;
            border: 1px solid $igi__color-icon-blue;
        }

        &.ImacIcon--bg-green {
            background-color: $igi__color-icon-green;
        }
        
        &.ImacIcon--igi-bg-blue {
            background-color: $igi__color-icon-blue;
            color: #fff;

            svg {
                width: 19px;
            }
        }

        &.ImacIcon--igi-bg-white {
            background-color: white;
            border: 1px solid $igi__color-icon-blue;

            svg {
                width: 14px;
            }
        }

        &.ImacIcon--igi-bg-green {
            background-color: $igi__color-icon-green;
            color: #fff;

            svg {
                width: 20px;
            }
        }
    }

    .ImacMenu {
        &__Logo {
            img {
                width: 100%;
                max-width: 154px;
            }
        }
    }

    @media screen and (max-width: 500px) {
        .ImacHeader__logoImageIgi {
            width: 120px;
            margin: 15px 0 0 0px;
        }
    }
}

