$menu-overlay-width-xs: 200px;
$menu-overlay-width-mobile: 80%;
$menu-overlay-width: 378px;
$mobile-break-point: 768px;
$xl-break-point: 1440px;
$xs-break-point: 440px;
$transition: 0.5s;
$color-link: #80949B;
$color-parent: #022A39;
$border-color: #BFC9CD;
$color-link: #022A39;
$color-link-menu: #80949b;
$color-link-menu-hover: #55a8f0;
$color-blue: #253081;
$color-icon-blue: #253081;
$color-icon-green: #CEDA40;
$color-storeswitcher-bg: #E5E9EB;
$color-black: #353737;

.theme-class--igi {
    --fontFamily: "Paralucent", sans-serif;
    --fontFamilyMenu: 'DM Sans', sans-serif;
    --fontFamilySecond: 'DM Sans', sans-serif;
    --colorFontDark: #2C2C31;
}
.theme-class--extr4 {
    --fontFamily: "canada-type-gibson", sans-serif;
    --fontFamilyMenu: "canada-type-gibson", sans-serif;
    --fontFamilySecond: "canada-type-gibson", sans-serif;
    --colorFontDark: #000;
}
.default {
    --fontFamily: "Paralucent", sans-serif;
    --fontFamilyMenu: "Paralucent", sans-serif;
    --fontFamilySecond: 'Roboto', sans-serif;
    --colorFontDark: #022A39;
}

@mixin selectImac {
    border: 0;
    padding: 0;
    line-height: 23px;
    height: 23px;
    background: none;
    background-clip: padding-box;
    border-radius: 0;
    font-size: 19px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
    font-family: var(--fontFamily);
    font-weight: bold;
}

.imac-overlay-open-bg.visible {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 30;
    content: ' ';
    background: #022A3980 0% 0% no-repeat padding-box;
}


.ImacSearchPopup {
    padding: 53px 32px 32px;
    top: 0;
    height: 190px;
    max-width: 100%;
    left: 5%;
    right: 5%;
    position: fixed;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 31;
    background-color: white;
    transition: $transition;
    transform: translateY(-100%);
    border-radius: 0 0 30px 30px;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;

    &.open {
        transform: translateY(0px);
    }

    &__title {
        font-family: var(--fontFamily);
        font-weight: bold;
        line-height: 37px;
        font-size: 30px;
        color: $color-black;
    }

    &__form {
        width: 100%;
    }

    &__input {
        margin-top: 34px;
        border: 1px solid $color-link;
        border-radius: 15px;
        height: 42px;
        width: 100%;
        padding: 20px 10px;
        box-sizing: border-box;

        &:focus-visible {
            outline: none;
        }
    };
}

.ImacMenuContainer {
    padding: 56px 20px 25px;
    top: 0;
    bottom:0;
    position:fixed;
    overflow-y:scroll;
    overflow-x:hidden;
    max-width: 90%;
    width: $menu-overlay-width-mobile;
    z-index: 31;
    left: 0;
    background-color: white;
    transition: $transition;
    transform: translateX(-100%);
    text-align: left;

    &.open {
        transform: translateX(0px);
    }

    &__close {
        display: none;
    }
}

.ImacMenu {
    &__Logo {
        display: none;
    }

    &__HeaderContainer {
        margin-bottom: 20px;
        display: block;
    }

    &__Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
    }

    &__HeaderSelect {
        @include selectImac;
    }

    &__MenuTitle {
        font-family: var(--fontFamilyMenu);
        font-weight: bold;
        line-height: 37px;
        font-size: 30px;
        color: $color-link;
    }

    &__HeaderTitle {
        font-family: var(--fontFamily);
        font-weight: bold;
        line-height: 19px;
        font-size: 15px;
        color: $color-link;
    }

    &__HeaderLink {
        font-family: var(--fontFamilySecond);
        line-height: 19px;
        font-size: 13px;
        color: $color-link;

        a,
        a:hover,
        a:visited,
        a:focus {
            font-family: var(--fontFamilySecond);
            line-height: 19px;
            font-size: 13px;
            color: $color-link;
            text-decoration: underline;
        }
    }

    &--border {
        margin-top: 8px;
        padding-top: 8px;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        margin-bottom: 8px;
        padding-bottom: 8px;
    }

    &__link {
        padding: 8px 0;
        font-family: var(--fontFamilyMenu);
        font-weight: normal;
        text-decoration: none;
        font-size: 15px;
        line-height: 19px;
        color: $color-link-menu;
        display: block;
        padding-left: 20px;

        &:hover {
            color: $color-black;
        }
    }

    &__parentLink {
        padding: 8px 0;
        font-size: 19px;
        line-height: 23px;
        font-family: var(--fontFamilyMenu);
        font-weight: bold;
        color: $color-parent;
        width: 100%;
        cursor: pointer;
        position: relative;

        > a {
            text-decoration: none;
            color: $color-parent;
        }

        &.hasChild {
            &::after {
                position: absolute;
                right: 0;
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.278' height='7.592' viewBox='0 0 13.278 7.592'%3E%3Cpath id='Icon_ionic-ios-arrow-down' data-name='Icon ionic-ios-arrow-down' d='M12.829,16.55l5.021-5.025a.945.945,0,0,1,1.34,0,.957.957,0,0,1,0,1.344L13.5,18.562a.947.947,0,0,1-1.309.028L6.464,12.873A.949.949,0,0,1,7.8,11.529Z' transform='translate(-6.188 -11.246)' fill='%2380949b'/%3E%3C/svg%3E%0A");
                width: 13px;
                height: 8px;
            }

            &.open {
                &::after {
                    transform-origin: 6px 10px;
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__childrenContainer {
        max-height: 0;
        transition: all .35s;
        padding: 0;
        opacity: 0;
        pointer-events: none;
        &.open {
            max-height: 300vh;
            opacity: 1;
            pointer-events: auto;
        }

        .ImacMenu__parentLink {
            padding: 8px 0;
            font-family: var(--fontFamilyMenu);
            font-weight: bold;
            text-decoration: none;
            font-size: 15px;
            line-height: 19px;
            color: $color-link;
            display: block;
            padding-left: 10px;

            &:after {
                right: 0;
            }
        }
    }
    &__cmsBlock {
        /* stile di base per il menu */
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        /* stile per i separatori tra le voci di menu */
        li.divider {
            height: 1px;
            margin: 8px 0;
            border-top: 1px solid $border-color;
        }

        /* stile per le voci di menu principali */
        li > a {
            display: block;
            padding: 8px 0;
            color: $color-parent;
            text-decoration: none;
            font-size: 19px;
            line-height: 23px;
            font-family: var(--fontFamilyMenu);
            font-weight: bold;
        }

        /* stile per le voci di menu con un sottomenu */
        li.parent {
            position: relative;

            & > div::after {
                position: absolute;
                right: 0;
                top: 7px;
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.278' height='7.592' viewBox='0 0 13.278 7.592'%3E%3Cpath id='Icon_ionic-ios-arrow-down' data-name='Icon ionic-ios-arrow-down' d='M12.829,16.55l5.021-5.025a.945.945,0,0,1,1.34,0,.957.957,0,0,1,0,1.344L13.5,18.562a.947.947,0,0,1-1.309.028L6.464,12.873A.949.949,0,0,1,7.8,11.529Z' transform='translate(-6.188 -11.246)' fill='%2380949b'/%3E%3C/svg%3E%0A");
                width: 13px;
                height: 8px;
            }

            &.open {
                &  > div::after {
                    transform-origin: 6px 10px;
                    transform: rotate(180deg);
                }
            }
            > div {
                display: block;
                padding: 8px 0;
                color: $color-parent;
                text-decoration: none;
                font-size: 19px;
                line-height: 23px;
                font-family: var(--fontFamilyMenu);
                font-weight: bold;
                cursor: pointer;
            }
        }

        /* stile per il sottomenu */
        li.parent > ul {
            max-height: 0;
            transition: all .35s;
            padding: 0;
            opacity: 0;
            pointer-events: none;
        }

        li.parent.open > ul {
            max-height: 300vh;
            opacity: 1;
            pointer-events: auto;
        }

        li.parent > ul > li {
            display: block;
            width: 100%;
        }

        li.parent > ul > li > a {
            display: block;
            padding: 8px 0 8px 10px;
            font-family: var(--fontFamilyMenu);
            font-weight: bold;
            text-decoration: none;
            font-size: 15px;
            line-height: 19px;
            color: $color-link;
        }

        li.level2 {
            padding-left: 10px;
            > div {
                display: block;
                padding: 8px 0;
                color: $color-parent;
                text-decoration: none;
                font-size: 15px;
                line-height: 20px;
                font-family: var(--fontFamilyMenu);
                font-weight: bold;
                cursor: pointer;
            }

            & > ul > li > a {
                font-size: 15px;
                line-height: 20px;
                font-family: var(--fontFamilyMenu);
                color: #80949B;
                font-weight: normal;

                &:hover {
                    color: #3D67AF;
                }
            }
        }
    }
}
.ImacHeader {
    box-shadow: 0 13px 23px #0000000D;
    border-radius: 0 0 30px 30px;
    opacity: 1;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;

    .imac-my-6 {
        margin: 0 6px;
    }

    &__search {
        cursor: pointer;
    }

    &__storeSwitcher {
        @include selectImac;
    }

    &__message {
        background-color: $color-blue;
        border-radius: 20px;
        padding: 7px 17px;
        color: white;
        font-family: var(--fontFamilySecond);
        font-size: 13px;
        line-height: 19px;
        text-align: center;
    }

    &__headerWrarpper {
        padding-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__hamburger {
        cursor: pointer;
        margin-right: 14px;
    }

    &__logo {
        margin-top: 4px;
    }

    &__logoImage {
        width: 100%;
        height: 55px;

        @media screen and (min-width: 380px){
            width: 165px;
            margin: auto -20px;
        }
    }


    &__logoImageIgi {
        width: 100%;
        height: 75px;

        @media screen and (min-width: 380px){
            width: 219px;
            margin: 15px 0 0 20px;
        }
    }

    &__center,
    &__storeSwitcher,
    &__storeSwitcherContainer,
    &__HeaderLogin {
        display: none;
    }

    &__left {
        display: flex;
        align-items: center;
    }

    &__right {
        display: flex;
        align-items: center;
        justify-self: end;
    }

    &__HeaderTitle {
        font-family: var(--fontFamily);
        font-size: 15px;
        line-height: 19px;
        color: $color-link;
        font-weight: bold;
    }

    &__HeaderLink {
        font-family: var(--fontFamilySecond);
        font-size: 13px;
        line-height: 19px;
        color: $color-link;
        font-weight: normal;

        a {
            font-family: var(--fontFamilySecond);
            font-size: 13px;
            line-height: 19px;
            color: $color-link;
            font-weight: normal;
        }
    }

    &__MenuItem {

    }

    &__MenuLink {
        color: $color-link;
        font-family: var(--fontFamilySecond);
        font-size: 19px;
        line-height: 23px;
        text-transform: uppercase;
        font-weight: bold;
        text-decoration: none;
    }
}

.ImacCartSummary {
    position: absolute;
    right: -3px;
    font-weight: bold;
    top: -8px;
    font-size: 10px;
    background-color: #fff;
    border: 1px solid #ededed;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    line-height: 0;
}

.ImacIcon {
    width: 42px;
    height: 42px;
    background-color: red;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.ImacIcon--bg-blue {
        background-color: $color-icon-blue;
    }

    &.ImacIcon--bg-white {
        background-color: white;
        border: 1px solid $color-icon-blue;
    }

    &.ImacIcon--bg-green {
        background-color: $color-icon-green;
    }
    &.ImacIcon--bg-transperent {
        background-color: transparent;
    }
    &.ImacIcon--color-black{
        color: #353737;
    }
}

/* Store Switcher */
.ImacHeader__storeSwitcherContainer,
.ImacMenu__HeaderLink {
    button {
        all: unset;
    }
}

.DropdownMenuContent {
    min-width: 110px;
    padding: 5px 0;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    margin-top: 10px;
    background: $color-storeswitcher-bg;
    box-shadow: 0 13px 23px #0000000D;
    margin-right: 50px;
    z-index: 999;

    @media screen and (min-width: $mobile-break-point) {
        margin-right: 30px;
    }
}

.DropdownMenuItem {
    font-size: 13px;
    line-height: 1;
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: 25px;
    padding: 10px 5px 10px 25px;
    position: relative;
    user-select: none;
    outline: none;
    border-bottom: 1px solid $border-color;
    font-family: var(--fontFamily);

    &:last-child {
        border-bottom: 0;
    }

    a {
        font-size: 19px;
        color: var(--colorFontDark);
        font-family: var(--fontFamily);
        font-weight: 600;
        text-decoration: none;
    }
}

.IconButton {
    font-family: inherit;
    height: 35px;
    width: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--colorFontDark);
    font-size: 19px;
    text-transform: uppercase;

    span {
        text-transform: uppercase;
        font-family: var(--fontFamily);
        font-size: 19px;
        color: var(--colorFontDark);
        font-weight: 600;
        margin-right: 5px;
        text-decoration: none;
    }
}

@keyframes slideUpAndFade {
    from {
        opacity: 0;
        transform: translateY(2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideRightAndFade {
    from {
        opacity: 0;
        transform: translateX(-2px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideDownAndFade {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideLeftAndFade {
    from {
        opacity: 0;
        transform: translateX(2px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}


@media screen and (min-width: $xs-break-point) {
    .ImacMenuContainer {
        width: $menu-overlay-width-mobile;
    }
    .Imac_Header {
        &__logoImage {
            margin: auto;
        }
    }
}

@media screen and (min-width: $mobile-break-point) {
    .ImacSearchPopup {
        padding: 80px 77px 60px;
        top: 0;
        height: 276px;
        width: 600px;
        left: 50%;
        transform: translateY(-100%) translateX(-50%);
        border-radius: 0 0 30px 30px;

        &.open {
            transform: translateY(0px) translateX(-50%);
        }

        span {
            line-height: 57px;
            font-size: 47px;
        }
    }
    .ImacMenuContainer {
        width: $menu-overlay-width;
        padding: 30px 48px 25px;

        &__close {
            display: block;
            position: absolute;
            // top: 40px;
            top: 25px;
            left: 48px;
            cursor: pointer;
            &::before {
                content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44'%3E%3Cg data-name='Raggruppa 7908' transform='translate(-287 -38)' fill='none' stroke='%23000000' stroke-width='2'%3E%3Crect data-name='Rettangolo 86' width='42' height='42' rx='18' transform='translate(288 39)'/%3E%3Cg data-name='Icon feather-plus' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath data-name='Tracciato 5280' d='m313.95 55.05-9.9 9.9'/%3E%3Cpath data-name='Tracciato 5281' d='m304.05 55.05 9.9 9.9'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                width: 42px;
                height: 42px;
            }
        }
    }
    .ImacMenu {
        &__Logo {
            display: flex;
            justify-content: flex-end;
        }
        &__HeaderContainer {
            display: none;
        }
    }

    .ImacHeader {
        &__storeSwitcher,
        &__storeSwitcherContainer,
        &__HeaderLogin {
            display: block;
        }
    }
}

@media screen and (min-width: $xl-break-point) {
    .ImacHeader {
        &__storeSwitcher,
        &__storeSwitcherContainer,
        &__HeaderLogin {
            display: block;
        }

        &__center {
            display: flex;

            > div {
                margin: 0 24px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.LogoIgiCo {
    .cls-1 {
        isolation: isolate;
    }

    .cls-2 {
        mix-blend-mode: multiply;
        opacity: 0.5;
    }

    .cls-3 {
        fill: none;
    }

    .cls-4 {
        fill: #289548;
    }

    .cls-5 {
        fill: #fff;
    }

    .cls-6 {
        fill: #c20e1a;
    }

    .cls-7 {
        fill: #636363;
    }
}

@import "App-igi";
@import "App-extr4";
