$extr4__menu-overlay-width-xs: 200px;
$extr4__menu-overlay-width-mobile: 300px;
$extr4__menu-overlay-width: 378px;
$extr4__mobile-break-point: 768px;
$extr4__xl-break-point: 1440px;
$extr4__xs-break-point: 440px;
$extr4__transition: 0.5s;
$extr4__color-link: #80949B;
$extr4__color-parent: #022A39;
$extr4__font-family-paralucent: "Paralucent", sans-serif;
$extr4__font-family-roboto: "Roboto", sans-serif;
$extr4__border-color: #BFC9CD;
$extr4__color-link: #022A39;
$extr4__color-link-menu: #80949b;
$extr4__color-link-menu-hover: #55a8f0;
$extr4__color-blue: #253081;
$extr4__color-icon-blue: #2C2C31;
$extr4__color-icon-green: #3D67AF;

.ImacHeader.theme-class--extr4 {
    padding-bottom: 20px;
    border-radius: 0;
}

.theme-class--extr4 {
    .ImacHeader__logo{
        margin-top: 0;
    }
    .ImacHeader__center{
        display: flex;
        @media screen and (max-width: 768px){
            margin-right: auto;
        }
    }
    .ImacHeader__menuWrapper {
        display: none;
    }
    &.ImacHeader {
        box-shadow: none;
        padding: 0 15px 15px;
    }
    .ImacHeader__headerWrarpper{
        padding-top: 15px;
    }
    .ImacHeader__logoImage{
        width: 60px;
        height: 47px;
        margin: auto 0;
        @media screen and (min-width: 380px){
            // width: 124px;
            // height: 97px;
            // margin: auto 0;
            width: 60px;
            height: 47px;
            margin: auto 0;
        }
    }
    &.ImacMenuContainer {
        @media screen and (max-width: 768px){
            padding: 20px 20px 20px;
        }
        &__close {
            &::before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44'%3E%3Cg id='Group_7908' data-name='Group 7908' transform='translate(-287 -38)'%3E%3Crect id='Rectangle_86' data-name='Rectangle 86' width='42' height='42' rx='21' transform='translate(288 39)' fill='none' stroke='%232c2c31' stroke-width='2'/%3E%3Cg id='Icon_feather-plus' data-name='Icon feather-plus' transform='translate(309 39.494) rotate(45)'%3E%3Cpath id='Path_5280' data-name='Path 5280' d='M18,7.5v14' transform='translate(-3.5)' fill='none' stroke='%232c2c31' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='Path_5281' data-name='Path 5281' d='M7.5,18h14' transform='translate(0 -3.5)' fill='none' stroke='%232c2c31' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }
    .ImacIcon {
        width: 24px;
        height: 24px;
        background-color:transparent;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &.ImacIcon--bg-blue {
            background-color: $extr4__color-icon-blue;
        }

        &.ImacIcon--bg-white {
            background-color: white;
            border: 1px solid $extr4__color-icon-blue;
        }

        &.ImacIcon--bg-green {
            background-color: $extr4__color-icon-green;
        }
    }

    .ImacMenu {
        &__Logo {
            margin-bottom: 20px;
            img {
                width: 100%;
                max-width: 60px;
            }
            svg{
                width: 60px;
                max-width: 60px;
                height: 47px;
            }
        }
    }
    .ImacHeader__MenuItem {
        padding-right: 30px;
    }
    
    .ImacHeader__MenuLink {
        color: #000;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 1.51px;
        &:hover {
            text-decoration: underline;
        }
    }
    .ImacMenu__parentLink {
        color: #000;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 1.51px;
        &:hover {
            text-decoration: underline;
        }
        >a {
            color: #000;
        }
    }
    .ImacMenu--border {
        @media screen and (max-width: 768px){
            border: 0;
        } 
    }
    .ImacSearchPopup__title{
        color: #000;
    }
    .ImacSearchPopup__input{
        border-color: #000;
    }
}
.header-fixed {
    .theme-class--extr4 {
        .ImacHeader__logoImage {
            @media screen and (min-width: 380px){
                width: 60px;
                height: 47px;
            } 
        }
    }
}

/*min 768*/
@media screen and (min-width: $mobile-break-point) {
    .theme-class--extr4 {
        .ImacMenuContainer {
            &__close {
                display: block;
                position: absolute;
                top: 30px;
                left: 48px;
                cursor: pointer;
                &::before {
                    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44'%3E%3Cg id='Group_7908' data-name='Group 7908' transform='translate(-287 -38)'%3E%3Crect id='Rectangle_86' data-name='Rectangle 86' width='42' height='42' rx='21' transform='translate(288 39)' fill='none' stroke='%232c2c31' stroke-width='2'/%3E%3Cg id='Icon_feather-plus' data-name='Icon feather-plus' transform='translate(309 39.494) rotate(45)'%3E%3Cpath id='Path_5280' data-name='Path 5280' d='M18,7.5v14' transform='translate(-3.5)' fill='none' stroke='%232c2c31' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='Path_5281' data-name='Path 5281' d='M7.5,18h14' transform='translate(0 -3.5)' fill='none' stroke='%232c2c31' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    width: 28px;
                    height: 28px;
                }
            }
        }
        .imac-my-6 {
            margin: 0 12px;
        }
    }
}
/*min 1024*/
@media screen and (min-width: $xl-break-point) {
    .theme-class--extr4 {
        .ImacHeader__hamburger{
            display: none;
        }
        .ImacHeader__menuWrapper {
            display: flex;
            align-items: center;
        }
        .imac-my-6 {
            margin: 0 12px;
        }
    }
}
